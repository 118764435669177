<template>
  <div class="home">
    <div class="home_bar">
      <div class="home_eadit">
        <i class="el-icon-edit-outline"></i>
      </div>
      <div class="home_logo">
        <img src="../../assets/logo.png" alt srcset />
        <span>称意云</span>
      </div>
      
      <div class="bar_item">
        <span :class="item.id==clickIndex?'active':''" v-for="(item,i) in menuList" :key="i" @click="menuClick(item)">
          <i :class="item.menuIcon"></i>{{item.name}}
        </span>
      </div>
    </div>
    <div class="home_content">
      <div class="t_title">
        <div class="title_name">{{ titles }}</div>
        <div class="barList">
          <i class="el-icon-edit-outline"></i>
          <i class="el-icon-edit-outline"></i>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      menuList: [
        {id:1,name:'场景应用',menuIcon:'el-icon-menu',menuUrl:'/setting/take'},
        {id:2,name:'用户标签',menuIcon:'el-icon-collection-tag'},
        {id:3,name:'内容标签',menuIcon:'el-icon-collection-tag'},
        {id:4,name:'多渠道授权',menuIcon:'el-icon-help',menuUrl:'/setting/empoewr'},
        {id:5,name:'成员管理',menuIcon:'el-icon-user',menuUrl:'/setting/user'},
        {id:6,name:'API设置',menuIcon:'el-icon-setting'},
      ],
      clickIndex: 1,//导航id
      titles:'场景应用'//当前页面标题
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    menuClick(item) {
      this.clickIndex = item.id
      this.titles=item.name
      this.$router.push(item.menuUrl)
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  .home_bar {
    position: relative;
    width: 236px;
    height: calc(100vh - 48px);
    padding: 24px;
    .bar_bottom {
      position: absolute;
      bottom: 10px;
      left: 44px;
      
    }
  }
}
.home_eadit{
  margin-bottom: 24px;
  text-align: right;
  i{
    font-size: 20px;
  }
}
.home_logo{
  text-align: center;
  img{
    width: 60px;
    height: 60px;
    background: #004F6E;
    border-radius: 50%;
  }
  span{
    display: block;
    font-weight: 600;
color: #333333;
line-height: 14px;
margin-top: 16px;
  }
}
.bar_item {
  margin-top: 24px;
  span {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 16px;
    border-radius: 4px;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
  }
  .active {
    background: #004f6e;
    color: #fff;
    i{
      color: #ffffff;
    }
  }
}
.home_content {
  width: 100%;
  background: #f7f8fa;
  height: calc(100vh - 60px);
  padding: 30px 24px;
}
.t_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
  .title_name{
    line-height: 22px;
  }
  .barList{
    i{
      margin-left: 24px;
      font-size: 20px;
      color: #86909C;
    }
  }
}



</style>
