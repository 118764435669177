<template>
  <div class="home">
    <div class="home_bar">
      <img src="../../assets/images/img_logo.png" alt srcset />
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-postcard"></i>
            <span>数字名片</span>
          </template>
          <el-menu-item-group>
            <template slot="title">分组一</template>
            <el-menu-item index="1-1">访客查询</el-menu-item>
            <el-menu-item index="1-2">数字名片配置</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-files"></i>
            <span>CRM</span>
          </template>
          <el-menu-item-group>
            <template slot="title">分组一</template>
            <el-menu-item index="2-1">客户管理</el-menu-item>
            <el-menu-item index="2-2">商机管理</el-menu-item>
            <el-menu-item index="2-3">客户跟进记录</el-menu-item>
            <el-menu-item index="2-4">合同管理</el-menu-item>
            <el-menu-item index="2-5">竞对查询</el-menu-item>
            <el-menu-item index="2-6">公海客户查询</el-menu-item>
            <el-menu-item index="2-7">CRM操作日志</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
      <div class="bar_bottom">
        <span class="active">
          <i class="el-icon-house"></i>成员管理
        </span>
        <router-link to="/setting/take">
          <el-link icon="el-icon-setting">企业空间设置</el-link>
        </router-link>

        <!-- <span>
          <i class="el-icon-setting"></i>企业空间设置
        </span>-->
      </div>
    </div>
    <div class="home_content">
      <div class="content_bar">
        <div class="soo_titles">CRM-{{ titles }}</div>
        <div class="soo_item">
          <i class="el-icon-bell aaa"></i>
          <i class="el-icon-menu aaa"></i>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="display: flex;align-items: center;">
              <img src="../../assets/logo.png" alt srcset style="margin-right: 5px;" />
              <span>称意云</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus">狮子头</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>
              <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="carts">
        <el-card class="box-card"  shadow="never">
        <div slot="header" class="clearfix">
          <span>全部分组</span>
          <el-button style="float: right; padding: 3px 0;margin-left:10px" type="text">添加成员</el-button>
          <el-button style="float: right; padding: 3px 0" type="text">创建分组</el-button>
        </div>
        <div style="padding: 24px;">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="setTitle">
          <el-table-column prop="nickName" label="分组" width="180"></el-table-column>
          <el-table-column prop="grouping" label="姓名/手机" width="180"></el-table-column>
          <el-table-column prop="phone" label="CRM角色"></el-table-column>
          <el-table-column prop="authority" label="数字名片"></el-table-column>
          <el-table-column prop="authority" label="账号/密码"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template>
              <el-button
                icon="el-icon-more-outline"
                type="text"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>
      </el-card>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      titles: "成员管理",
      tableData: [
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    cartClick(item) {
      console.log(123);
      this.$router.push(item.pathUrl);
    },
    setTitle({ rowIndex, columnIndex }) {
      return "background:#F7F8FA;";
      if (columnIndex == 0) {
        return "background:pink;";
      } else if (columnIndex == 1) {
        return "background:skyblue;";
      }
    },
  }
};
</script>
<style>
.el-card__body {
  padding: 0;
}
</style>
<style lang="less" scoped>
.home {
  display: flex;
  .home_bar {
    position: relative;
    width: 236px;
    height: calc(100vh - 48px);
    padding: 24px;
    .bar_bottom {
      width: 160px;
      position: absolute;
      bottom: 10px;
      left: 44px;
      .el-link {
        font-size: 14px;
        padding-left: 16px;
      }
      span {
        display: flex;
        align-items: center;
        height: 44px;
        padding-left: 16px;
        border-radius: 4px;
        font-size: 14px;
        margin-bottom: 24px;
        i {
          margin-right: 5px;
        }
      }
      .active {
        background: #004f6e;
        color: #fff;
        i {
          color: #ffffff;
        }
      }
    }
  }
}
.bar_item {
  margin-top: 24px;
  span {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 16px;
    border-radius: 4px;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
  }
  .active {
    background: #004f6e;
    color: #fff;
    i {
      color: #ffffff;
    }
  }
}
.home_content {
  width: 100%;
  background: #f7f8fa;
}
.content_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  border-radius: 0px 0px 4px 4px;
  padding: 0 24px;
}

.soo_item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 220px;

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #86909c;
  }
  .aaa {
    color: #86909c;
    font-size: 26px;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #86909c;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.carts{
  margin: 0 24px;
}
</style>
