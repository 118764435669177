<template>
    <div class="take_content">
      <div class="take_title">账号信息</div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">算力</span>
          <span>剩余0.01万</span>
        </div>
        <div class="take_itme_edit">
          <span>企业在执行协作工作流、数据验证、数据聚合</span>
        </div>
      </div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">内容存储流量</span>
          <span>剩余10G</span>
        </div>
        <div class="take_itme_edit">
          <span>企业在空间中上传及分享图片、音视频、文档</span>
        </div>
      </div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">AI生成次数</span>
          <span>剩余100次</span>
        </div>
        <div class="take_itme_edit">
          <span>通过 AIGC智能生成内容，以及Al分析</span>
        </div>
      </div>
      <div class="take_title take_titles">应用信息</div>
      <div class="take_input">在线协作</div>
      <div class="take_title_less">默认开通</div>
      <div class="take_input">内容运营</div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">内容统计</span>
          <span>默认开通</span>
        </div>
        <div class="take_itme_edit">
          <span>配置</span>
        </div>
      </div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">创建内容</span>
          <div class="take_item_check">
            <el-checkbox v-model="checked">微页面</el-checkbox>
            <el-checkbox v-model="checked">图片</el-checkbox>
            <el-checkbox v-model="checked">音视频</el-checkbox>
            <el-checkbox v-model="checked">文档</el-checkbox>
            <el-checkbox v-model="checked">短链接</el-checkbox>
          </div>
        </div>
        <div class="take_itme_edit">
          <span>配置</span>
        </div>
      </div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">投放内容</span>
          <div class="take_item_check">
            <el-checkbox v-model="checked">企业微信</el-checkbox>
            <el-checkbox v-model="checked">公众号</el-checkbox>
            <el-checkbox v-model="checked">抖音</el-checkbox>
            <el-checkbox v-model="checked">小红书</el-checkbox>
            <el-checkbox v-model="checked">百度</el-checkbox>
            <el-checkbox v-model="checked">官网</el-checkbox>
            <el-checkbox v-model="checked">小程序</el-checkbox>
          </div>
        </div>
        <div class="take_itme_edit">
          <span>配置</span>
        </div>
      </div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">AIGC</span>
          <span>默认开通</span>
        </div>
        <div class="take_itme_edit">
          <span>配置</span>
        </div>
      </div>
      <div class="take_title_less">用户运营</div>
      <div class="take_item">
        <div class="take_item_name">
          <span class="names">客户统计</span>
          <span>默认开通</span>
        </div>
        <div class="take_itme_edit">
          <span>配置</span>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
     
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.take_content{
  background: #fff;
  padding: 24px;
}
.take_title{
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
  padding-left: 24px;
}
.take_titles{
  margin-top: 24px;
}
.take_title_less{
color: #4E5969;
line-height: 22px;
  padding-left: 24px;
  margin-top: 24px;
  margin-bottom: 24px;

}
.take_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-shadow: 0px 1px 0px 0px #F2F3F5;
  .take_item_name{
    display: flex;
    span{
line-height: 22px;
    }
    .names{
      width: 100px;
      color: #4E5969;
      line-height: 22px;
      margin-right: 24px;
      padding-left: 24px;
    }
    
  }
}
.take_input{
  height: 70px;
  line-height: 70px;
  padding-left: 24px;
background: #F7F8FA;
box-shadow: 0px 1px 0px 0px #F2F3F5;
}
.take_itme_edit{
line-height: 22px;
}

</style>
