<!-- 登录 -->
<template>
  <div class="login" :style="{backgroundImage: 'url(' + (bgImg) + ')', backgroundSize:'cover'}">
    <div class="login-wrap">
      <div class="logos">
        <el-avatar :size="130" :src="circleUrl"></el-avatar>
      </div>
      <div class="bgg">
        <div class="changes">
          <span @click="handleBefore(1)" :class="ii==1?'act':''">登录</span>
          <span @click="handleAfter(2)" :class="ii==2?'act':''">注册</span>
        </div>
        <div class="login-content">
          <transition name="el-zoom-in-center" mode="out-in">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="80px"
              v-if="!show"
            >
              <el-form-item label="用户名" prop="number" v-if="!forgot&&nameF">
                <el-input v-model="ruleForm.number"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password" v-if="!forgot">
                <el-input
                  v-model="ruleForm.password"
                  type="password"
                  autocomplete="off"
                  @keyup.enter.native="submitLogin('ruleForm',ccc)"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="pass" v-if="forgotsure">
                <el-input v-model="ruleForm.pass" type="password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="phone" v-if="forgot||forgotsure">
                <el-col :span="13">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-col>

                <el-col class="line" :span="2">
                  <el-button
                    type="primary"
                    :disabled="dis"
                    style="margin-left: 8px;"
                    @click="getYan(yanz)"
                  >{{getCodes}}</el-button>
                </el-col>
              </el-form-item>
              <el-form-item label="验证码" prop="code" v-if="forgot||forgotsure">
                <el-input v-model="ruleForm.code"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitLogin('ruleForm',ccc)">{{loginName}}</el-button>
                <el-button
                  type="text"
                  @click="forgotPassword()"
                  style="float: right;"
                >{{forgotText}}</el-button>
              </el-form-item>
              <el-form-item class="forgots">
                <el-button type="text" @click="forgots()" :disabled="diss">忘记密码</el-button>
              </el-form-item>
            </el-form>
          </transition>
          <transition name="el-zoom-in-center" mode="out-in">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" v-if="show">
              <el-form-item label="用户名" prop="number">
                <el-input v-model="ruleForm.number"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="ruleForm.password" type="password"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="pass">
                <el-input v-model="ruleForm.pass" type="password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="phone">
                <el-col :span="12">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-col>

                <el-col class="line" :span="2">
                  <el-button
                    type="primary"
                    :disabled="dis"
                    style="margin-left: 8px;"
                    @click="getYan(2)"
                  >{{getCode}}</el-button>
                </el-col>
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <el-input v-model="ruleForm.code"></el-input>
              </el-form-item>
              <el-form-item label="邮箱地址" prop="email">
                <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">立即注册</el-button>
                <el-button @click="resetForm('ruleForm')">取消</el-button>
              </el-form-item>
            </el-form>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    var checkName = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'))
      } else {
        const res = await this.$http.post('/yanzhenNumbers', {
          numbers: this.ruleForm.number,
        })
        if (this.loginNum == 1) {
          callback()
        } else {
          if (res.data.code != 0) {
            return callback(new Error('用户名已被使用'))
          } else {
            callback()
          }
        }
      }
    }
    var checkPhone = async (rule, value, callback) => {
      if (!value) {
        this.dis = true
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        // console.log(reg.test(value))
        if (reg.test(value)) {
          this.dis = false
          const res = await this.$http.post('/yanzhenPhone', {
            phone: this.ruleForm.phone,
          })
          if (this.loginNum == 1) {
            callback()
          } else {
            if (res.data.code != 0) {
              return callback(new Error('手机号码已被使用'))
            } else {
              callback()
            }
          }
        } else {
          this.dis = true
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        if (this.ruleForm.password !== '') {
          this.$refs.ruleForm.validateField('pass')
        }
        callback()
      }
    }
    var checkPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        return callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }
    return {
      ii: 1,
      show: false,
      forgot: false,
      forgotsure: false,
      getCode: '获取验证码',
      getCodes: '获取验证码',
      getCodess: '获取验证码',
      forgotText: '手机号码登录',
      nameF: true,
      yanz: 1,
      loginNum: 1,
      ccc: 1,
      dis: true,
      diss: false,
      loginName: '立即登录',
      bgImg: require('../assets/images/login.jpg'),
      circleUrl: require('../assets/logo.png'),
      ruleForm: {
        number: '',
        password: '',
        pass: '',
        phone: '',
        code: '',
        email: '',
      },
      rules: {
        number: [
          { validator: checkName },
          { required: true, message: '用户名', trigger: 'blur' },
          {
            min: 8,
            max: 16,
            message: '长度在 8 到 16 个字符',
            trigger: 'blur',
          },
        ],
        password: [{ validator: checkPassword, trigger: 'blur' }],
        pass: [{ validator: checkPass, trigger: 'blur' }],
        phone: [{ validator: checkPhone, trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
        code: [
          { required: true, message: '验证码不能为空', trigger: 'blur' },
          {
            min: 6,
            max: 6,
            message: '验证码不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitLogin(formName, i) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (i == 1) {
            const res = await this.$http.post('/login', this.ruleForm)
            if (res.data.code == 0) {
              this.$message({
                message: '恭喜你，操作成功',
                type: 'success',
              })
              sessionStorage.setItem('codes', res.data.codes)
              sessionStorage.setItem('names', this.ruleForm.number)
              // this.$router.push('/home')
              if (sessionStorage.getItem('url')) {
                this.$router.push({
                  name: sessionStorage.getItem('url'),
                  params: {
                    //params 传递参数
                    names: this.ruleForm.number,
                  },
                })
              } else {
                this.$router.push({
                  name: 'home',
                  params: {
                    //params 传递参数
                    names: this.ruleForm.number,
                  },
                })
              }
            } else {
              this.$message.error(res.data.message)
            }
          } else {
            const res = await this.$http.post('/updatePassword', this.ruleForm)
            if (res.data.code == 0) {
              this.$message({
                message: '恭喜你，操作成功',
                type: 'success',
              })
            } else {
              this.$message.error(res.data.message)
            }
          }
        } else {
          this.$message({
            message: '请输入正确的内容',
            type: 'warning',
          })
          return false
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$http.post('/addUser', this.ruleForm)
          if (res.data.code == 0) {
            sessionStorage.setItem('codes', res.data.codes)
            sessionStorage.setItem('names', this.ruleForm.number)
            this.$router.push('/home')
            this.$message({
              message: '恭喜你，注册成功',
              type: 'success',
            })
          } else {
            this.$message({
              message: res.data.message,
              type: 'warning',
            })
          }
        } else {
          this.$message({
            message: '请输入正确的内容',
            type: 'warning',
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleAfter(i) {
      this.loginNum = 2
      this.ii = i
      this.ruleForm.number = ''
      this.ruleForm.password = ''
      this.ruleForm.phone = ''
      this.ruleForm.code = ''
      if (!this.show) {
        this.show = true
      }
    },
    handleBefore(i) {
      this.loginNum = 1
      this.ii = i
      this.ruleForm.number = ''
      this.ruleForm.password = ''
      this.ruleForm.phone = ''
      this.ruleForm.code = ''
      this.loginName = '立即登录'
      this.ccc = 1
      this.forgot = false
      this.forgotsure = false
      this.nameF = true
      if (this.show) {
        this.show = false
      }
    },
    forgotPassword() {
      this.forgot = !this.forgot
      this.ruleForm.number = ''
      this.ruleForm.password = ''
      this.ruleForm.phone = ''
      this.ruleForm.code = ''
      this.loginName = '立即登录'
      this.ccc = 1
      this.nameF = true
      if (this.forgot) {
        this.forgotText = '账号登录'
        this.forgotsure = false
        this.diss = true
        this.yanz = 1
      } else {
        this.forgotText = '手机号码登录'
        this.diss = false
      }
    },
    forgots() {
      this.forgotsure = true
      this.yanz = 3
      this.forgot = false
      this.nameF = false
      this.loginName = '修改密码'
      this.ccc = 2
    },
    // 获取验证码
    async getYan(i) {
      if (i == 1) {
        this.getCodes = 60
        var interval = setInterval(() => {
          this.getCodes--
          if (this.getCodes == 0) {
            this.getCodes = '获取验证码'
            clearInterval(interval)
          }
        }, 1000)

        const res = await this.$http.post('/longinMessage', {
          phone: this.ruleForm.phone,
        })
      } else if (i == 2) {
        this.getCode = 60
        var interval = setInterval(() => {
          this.getCode--
          if (this.getCode == 0) {
            this.getCode = '获取验证码'
            clearInterval(interval)
          }
        }, 1000)

        const res = await this.$http.post('/sendoutMessage', {
          phone: this.ruleForm.phone,
        })
      } else {
        this.getCodess = 60
        var interval = setInterval(() => {
          this.getCodess--
          if (this.getCodess == 0) {
            this.getCodess = '获取验证码'
            clearInterval(interval)
          }
        }, 1000)

        const res = await this.$http.post('/uppassMessage', {
          phone: this.ruleForm.phone,
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  .logos {
    position: absolute;
    top: -60px;
    left: 50%;
    margin-left: -60px;
    .el-avatar {
      background-color: #fff;
      padding: 18px;
      box-sizing: border-box;
    }
  }
  .changes {
    display: flex;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
    span {
      flex: 1;
      color: #495057;
      line-height: 36px;
      cursor: pointer;
      border-bottom: 2px solid #dee2e6;
    }
    .act {
      color: #2b8df9;
      border-bottom: 2px solid #2b8df9;
    }
  }
  .login-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 460px;
    left: 50%;
    border-radius: 6px;
    margin-left: -230px;
    // background: url(../assets/images/bg.jpg) no-repeat center;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 24%), 0 17px 50px 0 rgb(0 0 0 / 19%);
    .bgg {
      background-color: #fff;
      padding: 100px 70px 50px 70px;
      box-sizing: border-box;
      border-radius: 6px;
    }
    .login-content {
      width: 100%;
      height: 100%;
    }

    .el-zoom-in-center-leave-active {
      display: none;
    }
  }
  .forgots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-left: -40px;
    text-align: center;
    .el-button {
      font-size: 16px;
      color: #6a6f8c;
    }
  }
}
</style>
