<template>
  <div class="take_content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="抖音" name="1">
        <div class="tiktok">
          <div class="tt_menu">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-submenu index="1">
                <template slot="title">
                  <span>抖音号</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">抖音号</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <span>抖音坐席</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="2-1">企业号坐席</el-menu-item>
                  <el-menu-item index="2-2">员工号坐席</el-menu-item>
                  <el-menu-item index="2-3">普通号坐席</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
          <div class="totak_content">
            <div class="totak_soo">
              <div class="sousuo">
                <span style="margin-right: 24px;">媒体类型</span>
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <el-button type="primary">授权抖音号</el-button>
            </div>
            <el-table :data="tableData" style="width: 100%;" :header-cell-style="setTitle">
          <el-table-column prop="nickimage" label="抖音号" width="180"></el-table-column>
          <el-table-column prop="nickName" label="抖音授权" width="180"></el-table-column>
          <el-table-column prop="grouping" label="媒体类型"></el-table-column>
          <el-table-column prop="phone" label="功能授权"></el-table-column>
          <el-table-column prop="authority" label="授权到期日期"></el-table-column>
          <el-table-column prop="authoritys" label="坐席到期日期"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template>
              <el-button
                icon="el-icon-more-outline"
                type="text"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="微信" name="2"></el-tab-pane>
      <el-tab-pane label="小红书" name="3"></el-tab-pane>
      <el-tab-pane label="微博" name="4"></el-tab-pane>
      <el-tab-pane label="B站" name="5"></el-tab-pane>
      <el-tab-pane label="广告投放" name="6"></el-tab-pane>
      <el-tab-pane label="自有官网" name="7"></el-tab-pane>
      <el-tab-pane label="短信" name="8"></el-tab-pane>
      <el-tab-pane label="E-mail" name="9"></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      activeName: "1",
      tableData: [
        {
          nickimage: "祝",
          nickName: "阳光",
          grouping: "微信",
          phone: "已授权",
          authority: "2028-10-10",
          authoritys: "2029-9-8",
        }
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value: "",
      
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    setTitle({ rowIndex, columnIndex }) {
      return "background:#F7F8FA;";
      if (columnIndex == 0) {
        return "background:pink;";
      } else if (columnIndex == 1) {
        return "background:skyblue;";
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style lang="less" scoped>
.take_content {
  background: #fff;
  padding: 24px;
}
.user_soo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .add_soo .el-button {
    color: #004f6e !important;
  }
}
.tiktok {
  display: flex;
  width: 100%;
  .tt_menu {
    width: 236px;
  }
}
.totak_content {
  padding: 24px;
  width: calc(100% - 290px);
  .totak_soo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
}
</style>
