<template>
  <div class="take_content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="成员查询" name="first">
        <div class="user_soo">
          <el-input
            placeholder="搜索昵称/手机号"
            suffix-icon="el-icon-search"
            v-model="input1"
            style="width: 224px;"
          ></el-input>
          <div class="add_soo">
            <el-button type="text">添加成员</el-button>
            <el-button type="text">批量添加</el-button>
          </div>
        </div>
        <el-table :data="tableData" style="width: 100%" :header-cell-style="setTitle">
          <el-table-column prop="nickName" label="头像/昵称" width="180"></el-table-column>
          <el-table-column prop="grouping" label="分组" width="180"></el-table-column>
          <el-table-column prop="phone" label="联系手机"></el-table-column>
          <el-table-column prop="authority" label="权限"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template>
              <el-button
                icon="el-icon-more-outline"
                type="text"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="分组权限" name="second">
        <el-table :data="tabss" style="width: 100%" :header-cell-style="setTitle">
          <el-table-column prop="nickName" label="分组名称"></el-table-column>
          <el-table-column prop="grouping" label="应用权限"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template>
              <el-button
                icon="el-icon-more-outline"
                type="text"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      activeName: "first",
      tableData: [
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
        {
          nickimage: "老肖",
          nickName: "老肖",
          grouping: "直播",
          phone: "1348***645",
          authority: "团队所有者"
        },
      ],
      tabss: [
        {
          nickName: "内容组",
          grouping: "协作 内容 招商"
        },
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    setTitle({ rowIndex, columnIndex }) {
      return "background:#F7F8FA;";
      if (columnIndex == 0) {
        return "background:pink;";
      } else if (columnIndex == 1) {
        return "background:skyblue;";
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style lang="less" scoped>
.take_content {
  background: #fff;
  padding: 24px;
}
.user_soo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .add_soo .el-button {
    color: #004f6e !important;
  }
}
</style>
